<template>
  <div id="products" style="padding-top: 126px">
    <app-header />
    <div class="current_position w">
      <div class="current_position_text">当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(item, i) in $route.meta.breadcrumb"
          :to="item.path ? { path: item.path } : ''"
          :key="i"
          >{{ item.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div class="institution_list w">
      <div class="img">
        <img src="../../assets/image/组织.png" alt="" />
      </div>
      产品
    </div>
    <!-- 内容 -->
    <div class="product_box w">
      <!-- 产品内容 -->
      <div
        class="freight"
        v-for="item in productsList"
        :key="item.id"
        @click="$router.push(`/productsDetails/${item.id}`)"
      >
        <div class="freight_head">
          <img :src="item.product_img" alt="" />
        </div>
        <div class="freight_text">
          <div class="text">
            {{ item.product_name }}
          </div>
          <div class="price">{{ item.price }}</div>
        </div>
      </div>
    </div>
    <!-- 分页 -->

    <div class="paging w">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paging"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Page.count"
      >
      </el-pagination>
    </div>
    <app-footer />
  </div>
</template>

<script>
import Header from '../../components/Header/header.vue';
import Footer from '../../components/Footer/footer.vue';
import { product_list } from '../../api/policies.js';
export default {
  data() {
    return {
      paging: [10, 20, 30, 40],
      productsList: [], //律师列表
      Page: {
        page: '1',
        limit: '10',
        keyword: '',
        count: 0,
      },
    };
  },
  created() {
    this.product_list();
  },
  methods: {
    async product_list(page = '1', limit = '10') {
      const parameter = {
        page,
        limit,
        keyword: '',
      };
      const { result, count } = await product_list(parameter);
      this.Page = { ...this.Page };
      this.Page.count = count;
      this.productsList = result;
      console.log(count);
    },
    handleSizeChange(v) {
      console.log(v);
      this.Page.limit = v;
      this.product_list('1', this.Page.limit);
    },
    handleCurrentChange(v) {
      this.Page.page = v;
      this.product_list(v, this.Page.limit);
      console.log(v);
    },
  },
  components: { 'app-header': Header, 'app-footer': Footer },
};
</script>

<style scoped>
.product_box {
  display: grid;
  gap: 0px 37px;
  grid-template-columns: repeat(5, 1fr);
}
#products .paging {
  margin-top: 60px;
  margin-bottom: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* 	overflow: hidden;
       */
}
.w {
  width: 1520px;
  margin: 0 auto;
}
.current_position {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.current_position_text {
  font-size: 14px;
  color: #838383;
}
.d8f {
  color: #0d8eff;
}
.institution_list {
  display: flex;
  align-items: center;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.institution_list .img {
  width: 18px;
  height: 18px;
  /* border: 1px dashed #666; */
  margin-right: 20px;
}
.institution_list span img {
  width: 100%;
  height: 100%;
}
.introduce .th {
  height: 33.33%;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #333333;
}
.introduce .tb {
  height: 33.33%;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.introduce .tb2 {
  color: #595959;
}
.pic {
  transition: all 1s;
}
.pic:hover {
  transform: scale(1.5);
  /* transition: all 1s; */
}
.freight {
  width: 272px;
  height: 263px;
  margin-top: 30px;
  box-sizing: content-box;
  border: 1px solid #f2f2f2;
  /* box-shadow: -2px 0px 8px 2px rgba(0, 132, 255, 0.15); */
}
.freight .freight_head {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 272px;
  height: 170px;
  border-bottom: 1px solid #e8e8e8;
  object-fit: cover;
  overflow: hidden;
}
.freight_text {
  padding: 20px;
}
.freight .freight_head img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.freight_text .text {
  width: 100%;
  height: 18px;
  font-weight: 700;
  color: #333333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-spacing: normal;
}
.freight_text .price {
  font-weight: 700;
  font-size: 18px;
  margin-top: 15px;
  color: #ffa71d;
}
</style>
